<template>
    <div>
        <b-alert :variant="loading?'primary':'danger'" :show="!items">
            <div class="alert-body py-3 px-2">
                <span><strong v-if="loading">Veriler yükleniyor. <b-spinner label="Loading..." /></strong><strong v-else>Veri bulunamadı.</strong></span>
            </div>
        </b-alert>
        <b-card v-if="items">
            <!-- table -->
            <vue-good-table
                :columns="columns"
                :rows="items"
                :search-options="{enabled: false}"
                :pagination-options="{enabled: false}"
                :sort-options="{enabled: false}">
                <template
                    slot="table-row"
                    slot-scope="props"
                >

                    <div v-if="props.column.field === 'action'">
                        <b-button variant="outline-primary" :to="{name:'membership_packages', params:{type:props.row.id}}">
                          <feather-icon icon="ListIcon" />
                        </b-button>
                    </div>

                    <!-- Column: Common -->
                    <div v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </div>
                </template>

            </vue-good-table>
        </b-card>
    </div>
</template>

<script>
import {
    BAlert,
    BSpinner,
    BCard,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BImg,
    BButtonGroup,
    BButton,
    BForm,
    BRow,
    BCol


} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import store from '@/store'
import formData from 'form-data'
import vSelect from "vue-select";

export default {
    name: 'list',
    components: {
        BAlert,
        BSpinner,
        BCard,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        VueGoodTable,
        BImg,
        BButtonGroup,
        BButton,
        BForm,
        BRow,
        BCol,
        vSelect

    },
    data(){
        return {
            loading: true,
            items: false,
            preChange : {},
            categoryList:[],
            columns: [
                {
                    label: 'Kullanıcı Türü',
                    field: 'name',
                    sortable: false
                },
                {
                    label: 'İşlemler',
                    field: 'action',
                    sortable: false,
                    width: '160px',
                    align: 'center'
                },
            ]
        }
    },
    created() {
        let t = this


        t.$http.get('/admin/user/getRoles')
            .then(res => {
                if(res.data.status){
                    t.items = res.data.data
                }else if(res.data.message){
                    t.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Hata`,
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                            text: res.data.message,
                        },
                    })
                }
            }).catch(error=>{
            console.log(error)
        }).then(()=>{
            t.loading = false
        })
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.tableInput[readonly]{
    border: none !important;
}
</style>
